import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import BlogList from '../components/BlogList';

const BlogPage = () => {
  const {
    datoCmsBlogArchive: { seoMetaTags, title },
    latestPost,
    otherPosts,
  } = useStaticQuery(graphql`
    query BlogPageQuery {
      datoCmsBlogArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
      }
      latestPost: allDatoCmsBlog(
        sort: { fields: meta___firstPublishedAt, order: DESC }
        limit: 1
      ) {
        nodes {
          ...BlogCardFragment
          featuredImage {
            ...LatestBlogCardFeaturedImageFragment
          }
        }
      }
      otherPosts: allDatoCmsBlog(
        sort: { fields: meta___firstPublishedAt, order: DESC }
        skip: 1
      ) {
        nodes {
          ...BlogCardFragment
          featuredImage {
            ...OtherBlogCardFeaturedImageFragment
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <PageHeader heading={title} />
        <BlogList
          items={[...latestPost.nodes, ...otherPosts.nodes]}
          isBlogListing={true}
        />
      </main>
    </Layout>
  );
};

export default BlogPage;
